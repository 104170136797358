<template>
<div>
<div class="container">
	<div class="contents">
		<div class="section">
	<h1 v-if ="code === 'outOfTerm'" class="mypageTtl">スタンプラリー終了</h1>
	<h1 v-else class="mypageTtl">エラー画面</h1>

	<div class="section formWrap">
		<p>{{message}}</p>
		<p v-if ="code === 'system'">ご迷惑をおかけして申し訳ございません。<br>しばらくお待ちください。</p>
	</div>
	<div v-if ="code === 'outOfTerm'" class="layoutC mt30"><router-link :to="`/mypage/${ shortUrlCode }/`" class="btn">マイページへ戻る</router-link></div>
		</div>
	</div>
</div>
</div>
</template>

<script>
export default {
	props: ['code', 'id'],
	data() {
		return {
			message : '',
			shortUrlCode : '',
		}
	},
	mounted() {

		this.addPeople();

		var apiLoginToken = 'undefined';
		apiLoginToken = localStorage.getItem('loginToken');

		if(apiLoginToken == null || apiLoginToken == 'undefined'){
			var kyokucode = localStorage.getItem('kyokucode')
			if(kyokucode == '' || kyokucode == null){
				kyokucode = 'stp999';
			}
			var shortUrlCode = localStorage.getItem('shortUrlCode')
			if(shortUrlCode == '' || shortUrlCode == null){
				shortUrlCode = this.id;
			}

			this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
		}

		if(this.code == 'expired'){
			this.message = '短縮URLの有効期限切れ';
		}else if(this.code == 'noStamprally'){
			this.message = 'スタンプラリーが見つかりませんでした。'
		}else if(this.code == 'outOfTerm'){
			this.message = 'このスタンプラリーは終了しております。'
		}else if(this.code == 'unanswered'){
			this.message = 'アンケートが未回答です'
		}else if(this.code == 'noStampNote'){
			this.message = 'スタンプ帳が見つかりませんでした'
		}else if(this.code == 'paramError'){
			this.message = 'リクエストパラメータが不正です'
		}else if(this.code == 'session'){
			this.message = 'セッションエラー'
		}else if(this.code == 'system'){
			this.message = 'システムエラー'
		}

		this.shortUrlCode = localStorage.getItem('shortUrlCode')

	}
}
</script>

<style scoped>
.error { color: red; }
</style>
