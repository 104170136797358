<template>
<div>
<div id="mainVisual"><div class="inner"><img v-bind:src="stamprally.banner" alt=""></div></div>
<div class="container">
	<div class="contents">

		<div class="broadcastingLogo"><img v-bind:src="tv_station.logo" alt=""></div>
		<div class="section">
			<p>アンケートに回答して、ログインしてください。<br>なお、回答いただいた内容はサービス向上のほか、当社の業務の参考とさせていただきます。詳しくは利用規約をご確認ください。</p>
			<p class="require mt10">*は必須項目です。</p>
		</div>

		<div class="section formWrap">
			<form @submit.prevent="nextpage" id="contact">
				<div class="form">
					<label for="birth" class="formLabel">生年月</label><span class="require">*</span><br>
					<select class="year mr10" name="year" v-model="year" v-on:change="checkmethod">
						<option v-for="(year,key) in getYears()" name="year" v-bind:key='key' :value="year">{{ year }}</option>
					</select>
					<label>年</label>
					<select class="month ml10 mr10" name="month"  v-model="month" v-on:change="checkmethod">
						<option v-for="(month,key) in months" name="month" v-bind:key='key' :value="month">{{ month }}</option>
					</select>
					<label>月</label>
					<p class="error">{{ valid.year }}</p>
					<p class="error">{{ valid.month }}</p>

				</div>
				<div class="form">
					<label for="gender" class="formLabel">性別</label><span class="require">*</span><br>
					<ul class="radioList">
						<li>
							<div class="radioWrap"><input type="radio" value="1" id="gender01" v-model="gender" v-on:change="checkmethod"><label for="gender01">男性</label></div>
						</li>
						<li>
							<div class="radioWrap"><input type="radio" value="2" id="gender02" v-model="gender" v-on:change="checkmethod"><label for="gender02">女性</label></div>
						</li>
						<li>
							<div class="radioWrap"><input type="radio" value="3" id="gender03" v-model="gender" v-on:change="checkmethod"><label for="gender03">その他</label></div>
						</li>
					</ul>
					<p class="error">{{ valid.gender }}</p>
				</div>
				<div class="form">
					<label for="prefectures" class="formLabel">お住まいの郵便番号</label><span class="require">*</span><br>
					<input type="tel" maxlength="3" name="zipcode1" id="zipcode1" value="" v-model="zipcode1" v-on:change="checkmethod" class="tel zip01"> -
					<input type="tel" maxlength="4" name="zipcode2" id="zipcode2" value="" v-model="zipcode2" v-on:change="checkmethod" class="tel zip02">
					<p class="error">{{ valid.zip }}</p>
				</div>

				<div class="form">
					<dl v-for="(row, index) in questionnaires"  :key="index" class="mb10">
						<template v-if="row.hidden == '0'" >
							<template v-if="row.required == '1'" >
								<label for="" class="formLabel">{{ row.question }}</label><span class="require">*</span><br>
							</template>
							<template v-else>
								<label for="" class="formLabel">{{ row.question }}</label><span class=""></span><br>
							</template>
							<template v-if="row.type == '1'">
								<div v-for="(option, k) in row.questionnaire_options" :key="k">
									<input type="checkbox" v-model="row.answer" :value="option.id" v-on:change="checkmethod"/>
									{{ option.option }}
								</div>
							</template>
							<template v-else-if="row.type == '2'">
								<div v-for="(option, k) in row.questionnaire_options" :key="k">
									<input type="radio" v-model="row.answer" :value="option.id" v-on:change="checkmethod" />
									{{ option.option }}
								</div>
							</template>
							<template v-else-if="row.type == '3'">
								<textarea v-model="row.answer" v-on:change="checkmethod"></textarea>
							</template>
							<template v-else>
							</template>

							<p class='error'>{{ row.error }}</p>

						</template>
					</dl>
				</div>

				<div class="form">
					<label for="sms" class="formLabel">SMS受信</label><span class="require">*</span><br>
					<ul class="radioList">
						<li>
							<div class="radioWrap"><input type="radio" value="true" id="sms01" v-model="sms"><label for="sms01">受け取る</label></div>
						</li>
						<li>
							<div class="radioWrap"><input type="radio" value="false" id="sms02" v-model="sms"><label for="sms02">受け取らない</label></div>
						</li>
						<p>※以降、同様のメッセージが不要の場合は「受け取らない」を選択してください。</p>
						<p>※設定の内容はすべての参加中のスタンラリーに反映されます</p>
					</ul>
					<p class="error">{{ valid.sms }}</p>
				</div>
				<div class="layoutC mt20">
					<button type="submit" value="" class="btn loginBtn"><span>回答してログイン</span></button>
				</div>
			</form>
		</div>

	<!-- / #contents --></div>
<!-- /.container --></div>
</div>
</template>
<script>
	import axios from 'axios' //追記
	import cons from '../../util/const'
	import $ from 'jquery';

	var birth = '';
	var pref = '';

	var __pParams = __pParams || [];
	function setParams(params){
		__pParams.push(params);
	}

	//フォーム用のデータ初期設定
	export default {
		props: ['kyokucode', 'channel', 'id'],
		data() {
			return {
				cons: cons,
				year: '',
				month: '',
				gender: '',
				zipcode: '',
				zipcode1: '',
				zipcode2: '',
				pref: '',
				city: '',
				address: '',
				sms: 'true',
				message: '',
				valid:{
					year: '',
					month: '',
					gender: '',
					zip:'',
					pref: '',
					city: '',
					address: '',
					sms: '',
					message: ''
				},
				stamprally: [],
				tv_station: [],
				awards: [],
				stamp_book: [],
				questionnaires: [],
				pref_id: '',
				locality: '',
				street: '',
				results: '',
				result_code: '',
				error_message: '',
				months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],

			}
		},
		created() {
			this.$watch(
				// 2つのプロパティを含めた値を評価させる
				() => [this.zipcode1, this.zipcode2],
				// valueやoldValueの型は上で返した配列になる
				(value, oldValue) => {
					var zip7 = this.zipcode1 + this.zipcode2;
					if(zip7.length == 7){
						let _this = this
						new YubinBango.Core(zip7, function(addr) {
							_this.pref  = addr.region	// 都道府県
							_this.city = addr.locality  // 市区町村
							_this.address   = addr.street	// 町域
						})
					}
				}
			);
		},
		mounted() {
			// let yubinbangou = document.createElement('script')
			// yubinbangou.setAttribute('src', 'https://yubinbango.github.io/yubinbango-core/yubinbango-core.js')
			// document.head.appendChild(yubinbangou)

			this.apiLoginToken = localStorage.getItem('loginToken');

			if(this.apiLoginToken == null || this.apiLoginToken == 'undefined'){
				var kyokucode = localStorage.getItem('kyokucode')
				if(kyokucode == '' || kyokucode == null){
					kyokucode = 'stp'+this.channel;
				}

				var shortUrlCode = localStorage.getItem('shortUrlCode')
				if(shortUrlCode == '' || shortUrlCode == null){
					shortUrlCode = this.id;
				}

				this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
			}

			var token = "Bearer " + this.apiLoginToken;

			axios.get("/api/stamprally_detail", {
				params: {
					code: this.id,	//短縮URLコード
					stamprally_id: this.channel	//スタンプラリーID
				},
				headers: {
					'Content-Type':'application/json',
					"Authorization": token
				},
				data: {} //←これ！！！
			})
			.then(response => {
				this.results = response.data;
				this.result_code = this.results.result_code,
				this.stamprally  = this.results.stamprally,
				this.questionnaires  = this.results.stamprally.questionnaires,
				this.tv_station  = this.results.stamprally.tv_station,
				this.awards	  = this.results.stamprally.awards,
				this.stamp_book  = this.results.stamp_book
				this.$nextTick(() => {
					if(this.stamprally.css){
						var link_style = $('<link>').attr({
							'rel': 'stylesheet',
							'href': this.cons.common.cssurl+this.stamprally.css
						});
						$('body').append(link_style);
					}
				});

				for (let i = 0 ; i < this.questionnaires.length ; i++){
					if (this.questionnaires[i].type == 1)  {
						this.questionnaires[i].answer = [];
						this.questionnaires[i].error = "";
					} else {
						this.questionnaires[i].answer = "";
						this.questionnaires[i].error = "";
					}
				}

				if(this.result_code == 'E010'){
					// 短縮URLの有効期限切れ
					this.$router.push("/general/error/expired/");
				}else if(this.result_code == 'E011'){
					// スタンプラリーが見つかりませんでした。
					this.$router.push("/general/error/noStamprally/");
				}else if(this.result_code == 'E012'){
					// スタンプラリーの表示期間外
					this.$router.push("/stamp/error/outOfTerm/"+this.id);
				}else if(this.result_code == 'E013'){
					// アンケートが未回答です。
				}else if(this.result_code == 'E014'){
					// スタンプ帳が見つかりませんでした
					this.$router.push("/general/error/noStampNote/");
				}
			})
			.catch(err => {
				(this.errored = true),(this.error = err);

				if(this.result_code == 'E010'){
					// 短縮URLの有効期限切れ
					this.$router.push("/general/error/expired/");
				}else if(this.result_code == 'E011'){
					// スタンプラリーが見つかりませんでした。
					this.$router.push("/general/error/noStamprally/");
				}else if(this.result_code == 'E012'){
					// スタンプラリーの表示期間外
					this.$router.push("/stamp/error/outOfTerm/+this.id");
				}else if(this.result_code == 'E013'){
					// アンケートが未回答です。
				}else if(this.result_code == 'E014'){
					// スタンプ帳が見つかりませんでした
					this.$router.push("/general/error/noStampNote/");
				}else{
					if (err.response.status == 500 || err.response.status == 502) {
						// Internal Server Error
						this.$router.push("/general/error/system/");
					}else if(err.response.status == 405){
						// Lambda API error
						this.$router.push("/general/error/system/");
					}
				}
			});

			axios.get("/api/user_info", {
				params: {},
				headers: {
					'Content-Type':'application/json',
					"Authorization": token
				},
				data: {}
			})
			.then(response => {
				this.results = response.data;
				this.sms = this.results.user.is_sms_notification;
				if(this.results.user.birth != null){

					if (this.results.user.birth) {
						var ym = this.results.user.birth.split('-');
						this.year = ym[0];
						this.month = ym[1];
					}
				}
				if(this.results.user.gender != null){
					this.gender = this.results.user.gender;
				}
				if(this.results.user.post_code != null){
					this.zipcode = this.results.user.post_code.split('-');
					this.zipcode1 = this.zipcode[0];
					this.zipcode2 = this.zipcode[1];
				}

			})
			.catch(err => {
				(this.errored = true), (this.error = err);
				if (err.response.status == 500 || err.response.status == 502) {
					// Internal Server Error
					this.$router.push("/general/error/system/");
				}else if(err.response.status == 403 || err.response.status == 400){
					var kyokucode = localStorage.getItem('kyokucode')
					if(kyokucode == '' || kyokucode == null){
						kyokucode = 'stp999';
					}
					var shortUrlCode = localStorage.getItem('shortUrlCode')
					if(shortUrlCode == '' || shortUrlCode == null){
						shortUrlCode = this.id;
					}

					sessionStorage.setItem('fromLogout', 'fromLogout');
					this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
				}else if(err.response.status == 404 || err.response.status == 405){
					// Lambda API error
					this.$router.push("/general/error/system/");
				}
			});
		},
		methods:{
			getYears: function () {
				const from = 1900;
				const now = new Date();
				const to = now.getFullYear();
				return [...Array(to - from).keys()].map(x => x + from);
			},
			answerApi: function(){

				this.apiLoginToken = localStorage.getItem('loginToken');
				if(this.apiLoginToken == null || this.apiLoginToken == 'undefined'){
					var kyokucode = localStorage.getItem('kyokucode')
					if(kyokucode == '' || kyokucode == null){
						kyokucode = 'stp'+this.channel;
					}

					var shortUrlCode = localStorage.getItem('shortUrlCode')
					if(shortUrlCode == '' || shortUrlCode == null){
						shortUrlCode = this.id;
					}

					this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
				}

				var token = "Bearer "+ this.apiLoginToken;

				var notification = '';

				if(this.sms == "true"){
					notification = Boolean(true);
				}else if(this.sms === true){
					notification = Boolean(true);
				}else if(this.sms == "false"){
					notification = Boolean(false);
				}else if(this.sms === false){
					notification = Boolean(false);
				}else{
					notification = Boolean(true);
				}

				axios.post("/api/answer",{
						code : this.id,
						stamprally_id : this.channel,
						brith_year : this.year,
						brith_month : this.month,
						gender : this.gender,
						post_code_first : this.zipcode1,
						post_code_last : this.zipcode2,
						region : this.pref,
						city : this.city,
						extended_address : this.address,
						is_sms_notification : notification,
						questionnaires : this.questionnaires
					},{
						headers: {
							'Content-Type':'application/json',
							"Authorization": token
						}
					}
				)
				.then(response => {
					this.results = response.data;

					if(this.results.result_code == 'S000'){
						// アンケート回答成功
						let $gender_str = "";
						if (this.gender == '1') {
							$gender_str = 'male'
						} else if (this.gender == '2') {
							$gender_str = 'female'
						} else if (this.gender == '3') {
							$gender_str = 'other'
						} else {
							$gender_str = '';
						}

						var res = this.people(this.year + this.month, this.zipcode1,
						this.$router, "/"+this.kyokucode+"/stamprally_detail/"+this.channel+"/"+this.id+"/", $gender_str);

					}else if(this.results.result_code == 'E010'){
						// アンケート回答済
						this.$router.push("/"+this.kyokucode+"/stamprally_detail/"+this.channel+"/"+this.id+"/");
					}else if(this.results.result_code == 'E011'){
						//スタンプラリーが見つかりませんでした。
						this.$router.push("/general/error/noStamprally/");
					}else if(this.results.result_code == 'E001'){
						// リクエストパラメータが不正です。
						this.$router.push("/general/error/paramError/");
					}
				})
				.catch(err => {
					(this.message = 'アンケート回答エラー'),
					(this.error = err);

					if(this.results.result_code == 'E010'){
						// アンケート回答済
						this.$router.push("/"+this.kyokucode+"/stamprally_detail/"+this.channel+"/"+this.id+"/");
					}else if(this.results.result_code == 'E011'){
						//スタンプラリーが見つかりませんでした。
						this.$router.push("/general/error/noStamprally/");
					}else if(this.results.result_code == 'E001'){
						// リクエストパラメータが不正です。
						this.$router.push("/general/error/paramError/");
					}else{
						if (err.response.status == 500 || err.response.status == 502) {
							// Internal Server Error
							this.$router.push("/general/error/system/");
						}else if(err.response.status == 403 || err.response.status == 400){
							var kyokucode = localStorage.getItem('kyokucode')
							if(kyokucode == '' || kyokucode == null){
								kyokucode = 'stp'+this.channel;
							}
							var shortUrlCode = localStorage.getItem('shortUrlCode')
							if(shortUrlCode == '' || shortUrlCode == null){
								shortUrlCode = this.id;
							}
							this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
						}else if(err.response.status == 405){
							// Lambda API error
							this.$router.push("/general/error/system/");
						}
					}
				});
			},
			people: function(birth_ym,zipcode, router, url, gender){

				setParams({client_id: '235',c_1: 'leafnet',c_2: 'ClientSite',c_3: birth_ym,c_4: zipcode, c_5: gender});

				var iid;
				var done = false;
				var head = document.getElementsByTagName('head')[0]
				var script = document.createElement('script')
				script.src = "https://cdn.d2-apps.net/js/tr.js";
				//script.src = "http://localhost:8004/public/tr.js";
				//script.src = "https://sscallapi.dev/public/test2.js";
				//script.src = "https://sscallapi.dev/public/tr.js";

				//tr.jsが読み込み完了してから遷移処理を実行
				script.onload = script.onreadystatechange = function() {
					if ( !done &&
						(!this.readyState || this.readyState === "loaded" || this.readyState === "complete") ) {
						done = true

						//tr.js内のiframe読み込み完了してから画面遷移
						iid = setInterval(function(){
							if(document.getElementsByTagName("iframe").length > 0){
								//アンケート回答後の遷移
								console.log("router push")
								clearInterval(iid);
								router.push(url)
							}
						}, 100);

						// Handle memory leak in IE
						script.onload = script.onreadystatechange = null
						if ( head && script.parentNode ) {
							head.removeChild( script )
						}
					}
				}
				window.__pParams = __pParams;
				head.appendChild(script);
				return true;
			},
			// people2: function(){

			// 	let d2people = document.createElement('script');
			// 	// d2people.setAttribute('src', 'http://localhost:8004/public/tr.js');
			// 	d2people.setAttribute('src', 'https://cdn.d2-apps.net/js/tr.js');
			// 	document.head.appendChild(d2people);

			// },
			checkmethod: function(event){
				var error = 0;

				// valid year
				var year = this.year;

				if(!this.checkEmpty2(year)){

					this.valid.year = "年を入力してください";
					error++
				}else{
					this.valid.year = "";
				}

				// valid month
				var month = this.month;

				if(!this.checkEmpty2(month)){

					this.valid.month = "月を入力してください";
					error++
				}else{
					this.valid.month = "";
				}

				var gender = this.gender
				if(!this.checkEmpty2(gender)){
					this.valid.gender = "性別を選択してください"
					error++;
				}else{
					this.valid.gender = "";
				}

				//valid zip
				var zip1 = this.zipcode1
				var zip2 = this.zipcode2
				var zip1_maxlength = 3;
				var zip2_maxlength = 4;
				if(!this.checkEmpty(zip1) || !this.checkEmpty(zip2)){
					this.valid.zip = "郵便番号を入力してください"
					error++;
				}else if(!this.checkNumeric(zip1) || !this.checkNumeric(zip2)){
					this.valid.zip = "郵便番号は半角数字で入力してください"
					error++;
				}else{
					if(this.checkLength(zip1, zip1_maxlength) && this.checkLength(zip2, zip2_maxlength)){
						var zip7 = zip1 + zip2;
						var pref2 = '';
						new YubinBango.Core(zip7, function(addr2) {
							pref2  = addr2.region	// 都道府県
						})
						if(!pref2){
							this.valid.zip = "存在しない郵便番号です。ご確認の上、再度入力してください。"
							error++;
						}else{
							this.valid.zip = "";
						}
					}else{
						this.valid.zip = "存在しない郵便番号です。ご確認の上、再度入力してください。"
						error++;
					}
				}

				// valid selectedTypes
				// var sms = this.sms
				// if(!this.checkEmpty(sms)){
				// 	this.valid.sms = "SMS受信を選択してください"
				// 	error++;
				// }else{
				// 	this.valid.sms = "";
				// }

				let questions = this.questions;
				let question_error = false;
				for (let i = 0 ; i < this.questionnaires.length ; i++){

					this.$set(this.questionnaires[i], "error", new Date());

					let answer = this.questionnaires[i].answer;
					let question = this.questionnaires[i].question;
					let required = this.questionnaires[i].required;

					if (this.questionnaires[i].type == 1) {
						if(answer.length == 0 && required == 1) {
							this.questionnaires[i].error = question + "を選択してください";
							question_error = true;
						} else {
							this.questionnaires[i].error = "";
						}

					} else if (this.questionnaires[i].type == 2)  {
						if(answer == "" && required == 1) {
							this.questionnaires[i].error = question + "を選択してください";
							question_error = true;
						} else {
							this.questionnaires[i].error = "";
						}

					} else if (this.questionnaires[i].type == 3)  {
						if(!this.checkEmpty(answer) && required == 1) {
							this.questionnaires[i].error = question + "を入力してください";
							question_error = true;
						} else if(answer.length > 1000) {
							this.questionnaires[i].error = question + "は1000文字までで入力してください";
							question_error = true;
						} else {
							this.questionnaires[i].error = "";
						}
					}
				}

				if (question_error == true) {
					error++;
				}

				// this.questionnaires.sort();
				this.questionnaires.sort((a, b) => {
						return true;
				});

				//error count
				if(error  > 0){
					event.preventDefault()
				}else{
					return 0;
				}
			},
			nextpage: function(){
				if(this.checkmethod(event) == 0){
					this.answerApi();
				}
			},
			//未入力
			checkEmpty: function(inputdata){
				if(inputdata.trim() == ""){
					return false
				}else{
					return true
				}
			},
			checkEmpty2: function(inputdata){
				if(inputdata == ""){
					return false
				}else{
					return true
				}
			},
			//半角英数字
			checkNumeric: function(inputdata){
				var regExp = /^[0-9_]*$/
				return regExp.test(inputdata);
			},
			//文字数制限
			checkLength: function(inputdata, maxlength){
				var booleanLength = false
				inputdata.length == maxlength ? booleanLength = true : booleanLength = false;
				return booleanLength
			},
			//メール形式
			checkMail: function(address){
				var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
				if (reg.test(address)) {
					return true;
				} else {
					return false;
				}
			}
		}
	};
</script>

<style scoped>
.error { color: red; }
</style>
