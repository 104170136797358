<template>
<div>
<div id="mainVisual"><div class="inner"><img v-bind:src="stamprally.banner" alt=""></div></div>
<div class="container">
	<div class="contents">

		<div class="broadcastingLogo"><img v-bind:src="tv_station.logo" alt=""></div>
		<p v-if="stamp_close == 1" class="endedTxt">受付は終了しました。<br>ご参加ありがとうございます</p>
		<div class="section">
			<p class="period">開催期間：{{stamprally.open}}～{{stamprally.close}}</p>
			<p class="preline" v-html="stamprally.description"></p>
		</div>

		<div class="section stampRallyWrap">
			<ul class="stampRally">
				<li v-for="stamp in stamp_book" :class="stampCheck(stamp)" v-bind:key="stamp.oa_date">{{ stamp.oa_date }}</li>
			</ul>
		</div>
		<div class="stampRallyPrizeWrap">
			<div class="stampRallyPrize">
				<h2>プレゼント内容</h2>
				<div class="prizeWrap">
					<div class="prize" v-for="award in awards"  v-bind:key="award.prize">
						<div v-if="award.conditions_value <= cleared_cnt" class="img reached">
							<img v-bind:src="award.image_url" alt=""></div>
						<div v-else class="img">
							<img v-bind:src="award.image_url" alt=""></div>
						<div class="ttl">{{award.prize}}：{{award.name}}</div>
						<dl class="info">
							<dt>抽選日</dt>
							<dd>{{award.lottery_date}}</dd>
						</dl>
						<dl class="info">
							<dt>当選人数</dt>
							<dd>{{award.lottery_count}}名様</dd>
						</dl>
						<dl class="info">
							<dt>応募条件</dt>
							<dd>獲得スタンプ数{{award.conditions_value}}個以上</dd>
						</dl>
						<div class="txt"><p>{{award.details}}</p></div>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<ul class="notes attention">
				<li  class="preline" v-html="stamprally.rule"></li>
			</ul>
			<div class="links btnWrap btn2">
				<router-link :to="`/${ kyokucode }/stamprally_detail/${ channel }/contact/${ id }`" class="btn bdrBtn"><span>企画に関する<br>お問い合わせ</span></router-link>
				<a :href="stamprally.program_official_url" v-if="stamprally.program_official_url" target="_blank" class="btn bdrBtn external"><span>番組公式ページ</span></a>
			</div>
			<div class="btnWrap"><router-link :to="`/mypage/${ id }/`" class="btn btnS">参加中のスタンプラリーを見る</router-link></div>
		</div>

	<!-- / #contents --></div>
<!-- /.container --></div>
</div>
</template>

<script>
	import axios from 'axios' //追記
	import $ from 'jquery';
	import '../../assets/js/slick.js'
	import cons from '../../util/const'
	//フォーム用のデータ初期設定
	export default {
		props: ['kyokucode', 'id', 'channel'],
		data() {
			return {
				cons: cons,
				stamprally: [],
				tv_station: [],
				awards: [],
				stamp_book: [],
				result_code: [],
				stampOn: 'stamped',
				stampOff: 'gone',
				stampCancel: 'cancel',
				stampSch: 'schedule',
				toDay :'',
				stamp_date : '',
				stamp_close : 0,
				cleared_cnt : 0,
			}
		},
		beforeRouteLeave(to, from, next){document.body.style.display = "";next();},
		updated(){setTimeout(function(){document.body.style.display = "";},400)},
		// watch: {
		// 	'$route' (routeInstance) {
		// 		this.createTitleDesc(routeInstance);
		// 	}
		// },
		mounted() {
			// var routeInstance = this.$route;
			this.addPeople();

			document.body.style.display = "none";
			var dt = new Date();
			// var yyyy = dt.getFullYear();
			// var mm = dt.getMonth();
			// var dd = dt.getDate();
			var toDay = dt.getTime();
			var apiLoginToken = 'undefined';
			apiLoginToken = localStorage.getItem('loginToken');

			if(apiLoginToken == null || apiLoginToken == 'undefined'){
				var kyokucode = localStorage.getItem('kyokucode')
				if(kyokucode == '' || kyokucode == null){
					kyokucode = 'stp'+this.channel;
				}
				var shortUrlCode = localStorage.getItem('shortUrlCode')
				if(shortUrlCode == '' || shortUrlCode == null){
					shortUrlCode = this.id;
				}

				this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
			}

			var token = "Bearer " + apiLoginToken;

			axios.get("/api/stamprally_detail", {
				params: {
					stamprally_id: this.channel,	//スタンプラリーID
					station_id: this.kyokucode,		//スタンプラリーID
					param: this.id		//スタンプラリーID
				},
				headers: {
					'Content-Type':'application/json',
					"Authorization": token
				},
				data: {} //←これ！！！
			})
			.then(response => {
				this.results = response.data;
				this.result_code = this.results.result_code,
				this.stamprally = this.results.stamprally,
				this.tv_station = this.results.stamprally.tv_station,
				this.awards     = this.results.stamprally.awards,
				this.stamp_book = this.results.stamp_book
				this.$nextTick(() => {
					//bodyが表示されてからjqueryのプラグイン実行
					var interval_id = setInterval(function(){
						if($("body").is(":visible")){
							clearInterval(interval_id);

							if($('.stampRallyPrize').length){
								$('.stampRallyPrize .prizeWrap').slick({
									arrows: false,
									dots: false,
									slidesToShow: 2,
									slidesToScroll: 2,
									infinite: false
								});
							}
						}
					}, 10);
					if(this.stamprally.css){
						console.log(this.cons.common.cssurl+this.stamprally.css)
						var link_style = $('<link>').attr({
							'rel': 'stylesheet',
							'href': this.cons.common.cssurl+this.stamprally.css
						});
						$('body').append(link_style);
					}
					// this.createTitleDesc(routeInstance);
				});

				this.results.stamprally.description = this.autoLink(this.results.stamprally.description);
				this.results.stamprally.rule = this.autoLink(this.results.stamprally.rule);

				var stp_close_tmp = this.stamprally.close.split('/');
				var stp_close = new Date(stp_close_tmp[0], stp_close_tmp[1] - 1, stp_close_tmp[2], 23, 59);
				var stamprallyClose = stp_close.getTime();

				if(toDay > stamprallyClose){
					console.log('終了');
					this.stamp_close = 1;
				}

				for(let i=0;i<this.stamp_book.length;++i){
					if(this.stamp_book[i].is_skip === true || this.stamp_book[i].is_stamped === true){
						++this.cleared_cnt;
					}
				}

				if(this.result_code == 'E010'){
					// 短縮URLの有効期限切れ
					this.$router.push("/general/error/expired/");
				}else if(this.result_code == 'E011'){
					// stアンプラリーが見つかりませんでした。
					this.$router.push("/general/error/noStamprally/");
				}else if(this.result_code == 'E012'){
					// スタンプラリーの表示期間外
					this.$router.push("/stamp/error/outOfTerm/"+this.id);
				}else if(this.result_code == 'E013'){
					// アンケートが未回答です。
					this.$router.push("/general/error/unanswered/");
				}else if(this.result_code == 'E014'){
					// スタンプ帳が見つかりませんでした
					this.$router.push("/general/error/noStampNote/");
				}

				//独自CSS適用
				// const files = require.context("../../assets/css/unique/", true, /\.vue$/);
				// files.keys().forEach(key => {
				// 	if(key.replace(/(\.\/|\.vue)/g, '')+".css" == this.results.stamprally.css){
				// 		files(key).default;
				// 	}
				// });
			})
			.catch(err => {
				(this.errored = true), (this.error = err);
				if(this.result_code == 'E010'){
					// 短縮URLの有効期限切れ
					this.$router.push("/general/error/expired/");
				}else if(this.result_code == 'E011'){
					// stアンプラリーが見つかりませんでした。
					this.$router.push("/general/error/noStamprally/");
				}else if(this.result_code == 'E012'){
					// スタンプラリーの表示期間外
					this.$router.push("/stamp/error/outOfTerm/"+this.id);
				}else if(this.result_code == 'E013'){
					// アンケートが未回答です。
					this.$router.push("/general/error/unanswered/");
				}else if(this.result_code == 'E014'){
					// スタンプ帳が見つかりませんでした
					this.$router.push("/general/error/noStampNote/");
				}else{
					if (err.response.status == 500 || err.response.status == 502) {
						// Internal Server Error
						this.$router.push("/general/error/system/");
					}else if(err.response.status == 403 || err.response.status == 400){
						var kyokucode = localStorage.getItem('kyokucode')
						if(kyokucode == '' || kyokucode == null){
							kyokucode = 'stp'+this.channel;
						}
						var shortUrlCode = localStorage.getItem('shortUrlCode')
						if(shortUrlCode == '' || shortUrlCode == null){
							shortUrlCode = this.id;
						}

						this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
					}else if(err.response.status == 405){
						// Lambda API error
						this.$router.push("/general/error/system/");
					}
				}
			});
		},
		methods: {
			// createTitleDesc : function(routeInstance){
			// 	// タイトルを設定
			// 	if(routeInstance.meta.title){
			// 		var setTitle = this.stamprally.name + ' | チョップラ！';
			// 		document.title = setTitle;
			// 	} else {
			// 		document.title = 'チョップラ！'
			// 	}
			// },
			stampCheck: function(stamp){
				var date = new Date();
				var oaDate = stamp.accept_end.split(' ');
				var oaDateDay = oaDate[0].split('-');
				var oaDateTime = oaDate[1].split(':');
				var book_date = new Date(oaDateDay[0], oaDateDay[1]-1, oaDateDay[2], oaDateTime[0], oaDateTime[1], oaDateTime[2]);

				if (stamp.is_skip === true) {
					return this.stampCancel;
				}else if(stamp.is_stamped === true){
					return this.stampOn;
				}else if(book_date.getTime() <  date.getTime() && stamp.is_stamped === false){
					return this.stampOff;
				}else if(book_date.getTime() >= date.getTime() && stamp.is_stamped === false){
					return this.stampSch;
				} else {
					return this.stampSch;
				}
			},
			autoLink: function(text) {

				if (text) {

					var urls = text.match(/(https?:\/\/[^\s]*)/g);

					if (urls == null) {
						return text;
					}

					for (const url of urls) {

						console.log(url);

						var index = url.lastIndexOf('(');

						var a_url = url;
						var label = "";
						if (index >-1) {
							label = url.substring(index + 1, url.length -1);
							a_url = url.substring(0, index);
						} else {
							a_url = url;
							label = url;
						}

						var tag = "<a href='" + a_url + "' target='_blank' class='detail_link' >" + label + "</a>";

						text = text.replace(url, tag);
					}

					return text;

				} else {
					return  '';
				}
			},

		}

	}
</script>
