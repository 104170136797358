<template>
<div>
	<form @submit.prevent="checkmethod2" id="contact">
		<div class="form">
			<label for="birth" class="formLabel">お名前（ニックネーム可）</label><br>
			<input v-bind:id="cons.contact.usernameId" v-bind:name="cons.contact.usernameId" maxlength="20" size="20" type="text" v-model="user.name" v-on:change="checkmethod">
			<p class="error">{{ user.valid.name }}</p>
		</div>
		<div class="form">
			<label for="birth" class="formLabel">携帯電話番号</label><br>
			<input id="phone" maxlength="11" name="phone" size="20" type="text" v-model="user.tel" v-on:change="checkmethod">
			<p class="error">{{ user.valid.tel }}</p>
		</div>
		<div class="form">
			<label for="birth" class="formLabel">メールアドレス</label><br>
			<input id="email" maxlength="80" name="email" size="20" type="text" v-model="user.mail" v-on:change="checkmethod">
			<p class="error">{{ user.valid.mail }}</p>
		</div>
		<div class="form">
			<label for="birth" class="formLabel">お問い合わせ種別</label><br>
			<div class="selectWrap">
				<select id="type" name="type" v-model="user.selectedTypes" v-on:change="checkmethod">
					<option value="">--なし--</option>
					<option value="ログインについて">ログインについて</option>
					<option value="ルールについて">ルールについて</option>
					<option value="番組について">番組について</option>
					<option value="抽選について">抽選について</option>
					<option value="賞品について">賞品について</option>
					<option value="登録情報について">登録情報について</option>
					<option value="その他">その他</option>
				</select>
				<p class="error">{{ user.valid.selectedTypes }}</p>
			</div>
		</div>
		<div class="form">
			<label for="birth" class="formLabel">お問い合わせ内容</label><br>
			<textarea name="description" v-model="user.message" v-on:change="checkmethod"></textarea>
				<p class="error">{{ user.valid.message }}</p>
		</div>

		<div class="layoutC mt20">
			<button type="submit" name="submit"  class="btn">確認画面へ</button>
		</div>
	</form>
	<div class="layoutC mt30"><router-link :to="`/${ kyokucode }/stamprally_detail/${ channel }/${ id }/`" class="btn"><span>スタンプラリー詳細に戻る</span></router-link></div>
</div>
</template>

<script>
import cons from '../../util/const'

export default {
	data() {
		return {
			cons: cons
		};
	},
	props: {
		kyokucode:String,
		id:String,
		channel:String,
		user: Object
	},
	methods: {
		checkmethod: function(event){
			var error = 0;

			//valid name
			var name = this.user.name
			if(!this.checkEmpty(name)){
				this.user.valid.name = "お名前を入力してくさい"
				error++
			}else{
				this.user.valid.name = ""
			}

			//valid tel
			var tel = this.user.tel
			var tel_maxlength = 11;
			if(!this.checkEmpty(tel)){
				this.user.valid.tel = "携帯電話番号を入力してください"
				error++;
			}else if(!this.checkNumeric(tel)){
				this.user.valid.tel = "携帯電話番号は半角数字のみで入力してください"
				error++;
			}else if(!this.checkTelLength(tel, tel_maxlength)){
				this.user.valid.tel = "携帯電話番号は半角数字11桁で入力してください"
				error++;
			}
			else{
				this.user.valid.tel = "";
			}

			//valid mail
			var mail = this.user.mail
			if(!this.checkEmpty(mail)){
				this.user.valid.mail = "メールアドレスを入力してください"
				error++;
			}else if(!this.checkMail(mail)){
				this.user.valid.mail = "メールアドレスはメール形式で入力してください。（半角英数字のみ）"
				error++;
			}
			else{
				this.user.valid.mail = "";
			}

			//valid selectedTypes
			var selectedTypes = this.user.selectedTypes
			if(!this.checkEmpty(selectedTypes)){
				this.user.valid.selectedTypes = "お問い合わせ種別を選択してください"
				error++;
			}else{
				this.user.valid.selectedTypes = "";
			}

			//valid selectedTypes
			var message = this.user.message
			var message_maxlength = 1000;
			if(!this.checkEmpty(message)){
				this.user.valid.message = "お問い合わせ内容を入力してください"
				error++;
			}else if(!this.checkMaxLength(message, message_maxlength)){
				this.user.valid.message = "お問い合わせ内容は"+message_maxlength+"文字以内で入力してください"
				error++;
			}else{
				this.user.valid.message = "";
			}

			//error count
			if(error  > 0){
				event.preventDefault()
			}else{
				return 0;
			}
		},
		checkmethod2: function(){
			if(this.checkmethod(event) == 0){
				this.$router.push('/' + this.user.stamp_rally.kyokucode + '/stamprally_detail/' + this.user.stamp_rally.channel + '/contact/confirm/' + this.user.stamp_rally.id);
			}
		},
		//未入力
		checkEmpty: function(inputdata){
			if(inputdata.trim() == ""){
				return false
			}else{
				return true
			}
		},
		//半角英数字
		checkString: function(inputdata){
			var regExp = /^[a-zA-Z0-9_]*$/
			return regExp.test(inputdata);
		},
		//半角数字
		checkNumeric: function(inputdata){
			var regExp = /^[0-9_]*$/
			return regExp.test(inputdata);
		},
		//文字数制限
		checkMaxLength: function(inputdata, maxlength){
			var booleanLength = false
			inputdata.length <= maxlength ? booleanLength = true : booleanLength = false;
			return booleanLength
		},
		checkTelLength: function(inputdata, maxlength){
			var booleanLength = false
			if(inputdata.length != maxlength){
				booleanLength = false;
			}else{
				booleanLength = true;
			}
			return booleanLength
		},
		//メール形式
		checkMail: function(address){
			var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
			if (reg.test(address)) {
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>

<style scoped>
.error { color: red; }
</style>