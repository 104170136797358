<template>
<div>
	<div class="section formWrap">
		<form v-bind:action="cons.contact.sendurl" id="contact">
			<input type="hidden" name="orgid" v-model="cons.contact.orgid">
			<input type="hidden" name="retURL" v-bind:value="cons.common.baseurl + cons.contact.retURL">
			<input type=hidden name="recordType" id="recordType" v-bind:value="cons.contact.recordType">
			<!--  ----------------------------------------------------------------------  -->
			<!--  注意: これらの項目は、省略可能なデバッグ用要素です。デバッグモードでテストを行う場合は、これらの行をコメント解除してください。        -->
			<!--  <input type="hidden" name="debug" v-bind:value="cons.contact.debug">                              -->
			<!--  <input type="hidden" name="debugEmail" v-bind:value="cons.contact.debugEmail">    -->
			<!--  ----------------------------------------------------------------------  -->
			<input id="name" name="name" type="hidden" v-model="user.name">
			<input id="phone" name="phone" type="hidden" v-model="user.tel">
			<input id="mail" name="email" type="hidden" v-model="user.mail">
			<input id="type" name="type" type="hidden" v-model="user.selectedTypes">
			<input id="description" name="description" type="hidden" v-model="user.message">
			<input  id="subject" maxlength="80" name="subject" size="20" type="hidden" v-bind:value="user.selectedTypes+ ' '+now"/><br>


			<div class="form">
				<label for="birth" class="formLabel">お名前</label><br>
				<p>{{ user.name }}</p>
			</div>
			<div class="form">
				<label for="birth" class="formLabel">携帯電話番号</label><br>
				<p>{{ user.tel }}</p>
			</div>
			<div class="form">
				<label for="birth" class="formLabel">メールアドレス</label><br>
				<p>{{ user.mail }}</p>
			</div>
			<div class="form">
				<label for="birth" class="formLabel">お問い合わせ種別</label><br>
				<p>{{ user.selectedTypes }}</p>
			</div>
			<div class="form">
				<label for="birth" class="formLabel">お問い合わせ内容</label><br>
				<p style="white-space: pre-wrap;word-wrap:break-word;">{{ user.message }}</p>
			</div>
			<div class="btnWrap btn2 mt20">
				<a v-on:click="$router.back()" class="btn bdrBtn">修正する</a>
				<input type="submit" value="送信する" class="btn">
			</div>
		</form>
	</div>
</div>
</template>

<script>
import cons from '../../util/const'

export default {
	data() {
		return {
			cons: cons,
			now: "0000/00/00 00:00:00"
		};
	},
	props: {
		user: Object
	},
	mounted(){

        if (this.user.mail == "") {
            this.$router.back();
        }

		this.addPeople();
		var now = new Date();
		var year = now.getFullYear();
		var mon = now.getMonth()+1; //１を足すこと
		var day = now.getDate();
		var hour = now.getHours();
		var min = now.getMinutes();
		var sec = now.getSeconds();

		//出力用
		this.now = year + "/" + mon + "/" + day + " " + hour + ":" + min + ":" + sec;
	}
};
</script>
