<template>
<div>
	<div id="app">
		<div class="container">
			<div class="contents">
				<div class="section">
					<h1 class="pageTtl">ログアウト</h1>
					<p>{{message}}</p>
				</div>
				<div class="section">
					<div class="layoutC mt20"><input type="button" value="ログイン" class="btn loginBtn" v-on:click="loginLink"></div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import axios from 'axios' //追記

//フォーム用のデータ初期設定
export default {
	data() {
		return {
			results : [],
			apiLoginToken : '',
			message : '',
		}
	},
	mounted() {
		this.addPeople();

		this.apiLoginToken = this.$localStorage.get('loginToken', 'not entry')

		const token = "Bearer "+this.apiLoginToken;

		axios.post("/api/logout",{
			headers: {
				'Content-Type':'application/json',
				"Authorization": token
			},
			data: {} //←これ！！！
		})

		.then(response => {
			this.results = response.data;
			console.log(this.results);//結果コード
			localStorage.removeItem('loginToken')

			this.message = 'ログアウトしました。'

		})
		.catch(err => {

			(this.message = 'ログアウトしました。'),
			(this.error = err);
			localStorage.removeItem('loginToken')
		});
	},
	methods: {
		loginLink: function(){
			var kyokucode = this.$localStorage.get('kyokucode', 'not entry')
			var shortUrlCode = this.$localStorage.get('shortUrlCode', 'not entry')
			sessionStorage.setItem('fromLogout', 'fromLogout');

			this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
		},
	}
}
</script>

<style scoped>
.error { color: red; }
</style>