<template>
<div>
<div class="container">
	<div class="contents">
		<div v-if="$route.meta.isComplete !== true" class="section" >
			<h1 class="mypageTtl">SMS送信設定</h1>
			<p>SMS 受信設定を変更できます。<br>変更した設定は、参加中のスタンプラリー全てに適用されます。</p>
		</div>
		<div v-if="$route.meta.isComplete === true" class="section" >
			<h1 class="mypageTtl">SMS送信設定の変更</h1>
			<p>設定を保存しました。</p>
		</div>

		<div class="section formWrap">
			<router-view v-bind:smssettings.sync="smssettings"></router-view>
		</div>
	</div>
</div>
</div>
</template>

<script>

//フォーム用のデータ初期設定
export default {
	props: ['id'],
	data() {
		return {
			smssettings: {
				is_sms_notification: '',
			}
		}
	},
	beforeCreate () {
		if (window.name != "any") {
			location.reload();
			window.name = "any";
		} else {
			window.name = "";
		}
	},
	mounted(){

	}
}
</script>