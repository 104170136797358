<template>
<div>
<div id="app">
<div id="mainVisual"><div class="inner"><img v-bind:src="results_banner" alt=""></div></div>
<div class="container">
	<div class="contents">
		<div class="broadcastingLogo"><img v-bind:src="results_tv_logo" alt=""></div>
			<div class="section">
				<p>スタンプを集めてして賞品をGETしよう！<br>携帯電話番号を入力して、スタンプラリーに参加してください。</p>
			</div>

			<div class="section formWrap">
			<!--
				<form v-on:submit="$router.push('/:id/answer/:channel/')" id="contact" >
			-->
				<form @submit.prevent="onSubmit1" id="contact">
					<div class="form">
						<label for="birth" class="formLabel">携帯電話番号</label><br>
						<input id="phone" maxlength="11" name="phone" size="20" type="tel" v-model="user.tel" v-on:change="checkmethod">
						<p class="error">{{ message1 }}</p>
						<p class="error">{{ message2 }}</p>
						<p class="error">{{ user.valid.tel }}</p>
					</div>
					<div class="mt20">
					</div>
					<div class="layoutC mt20"><input type="button" value="ログイン" class="btn loginBtn" v-on:click="check2method"></div>
				</form>
			</div>
		</div>
	</div>
</div>
<div id="terms" class="popupWinWrap">
	<div class="popupWin">
		<a href="javascript:void(0);" class="close termsLead"></a>
		<h2 class="popupTtl">ご利用方法</h2>
		<div class="popupBody">
			<p>「チョップラ！」は、対象とするプレゼント企画に応募すると、スタンプがもらえて、集めたスタンプの数に応じて賞品の抽選に参加ができる、ふだんのプレゼント企画にちょっとだけ楽しみをプラスしてくれるサービスです。</p>
			<p class="layoutC txtB">スタンプラリーのご利用について</p>

			<h3>１．ご利用方法</h3>
			<ol class="numList">
				<li>１）番組内で告知した応募電話番号にスマートフォンから電話をかけるとスタンプを１つ獲得することができます。</li>
				<li>２）電話をかけたスマートフォンの電話番号宛にSMS（ショートメッセージ）が送信されます。SMS本文に記載されたURLからスタンプ帳にアクセスし、スタンプラリーの詳細情報や獲得したスタンプを確認することができます。</li>
				<li>３）SMSが送信されるタイミング、およびスタンプ帳にスタンプが押されるタイミングは、電話をかけてから約５分後になります。（応募が集中した際などに大幅な遅れが発生することがあります）</li>
				<li>４）SMSは、電話をかけた回数に応じて送信されます。SMSの送信が不要な場合は、アンケート画面またはマイページからSMS受信設定を「受け取らない」に変更してください。</li>
				<li>５）スタンプラリー対象のプレゼント企画の日程は、スタンプ帳で確認することができます。対象となる電話番号の受付期間内に複数回応募しても、獲得できるスタンプは１つとなります。</li>
				<li>６）スタンプラリー対象のプレゼント企画が中止になった場合、プレゼントに応募することなくスタンプを獲得したものとみなします。</li>
				<li>７）スタンプラリーの抽選はスタンプ帳に記載された抽選日に自動的に行われます。応募条件を達成し、かつアンケートに回答している方が抽選対象となります。</li>
				<li>８）当選発表は当選者への連絡をもって行います。スタンプ帳上で当選結果の発表は行いません。</li>
				<li>９）プレゼントの発送は、当選のご連絡からおよそ2か月以内に行います。</li>
				<li>10）スタンプ帳は、スタンプラリー終了後、原則として30日間閲覧することができます。</li>
			</ol>

			<h3>２．ご注意事項</h3>
			<ol class="numList">
				<li>１）ログイン後２４時間操作がなかった場合、自動的にログアウトされます。ログアウトされた場合、再度ログイン画面へ戻ってログインをし直してください。</li>
				<li>２）ログインページで携帯電話番号の入力を３回間違えた場合、最後の操作から１時間ロックされます。ログイン操作は、１時間以上経過後に行ってください。</li>
				<li>３）スタンプは、電話をかけたスマートフォンの電話番号に対し付与されるため、異なる電話番号から参加した場合や、スタンプラリー開催中に電話番号を変更された場合、獲得したスタンプを引き継ぐことはできません。</li>
				<li>４）スタンプラリーについてのお問い合わせは、「お問い合わせ」「企画に関するお問い合わせ」ボタンから行うことができます。</li>
			</ol>

			<h3>３．ご利用条件</h3>
			<ol class="numList">
				<li>１）スタンプラリーに参加するための通話料、スタンプ帳を閲覧するための接続費用は、利用者のご負担になります。</li>
				<li>２）スタンプ帳にアクセスするため、SMSを受信できる携帯電話が必要になります。</li>
				<li>３）スタンプ帳の推奨環境は以下のとおりとします。<br>
				iOS<br>
				・iOS13以降 / iPhone6以上 / Safari最新版<br>
				Android<br>
				・Android9.0以降 / Google Chrome最新版<br>
				</li>
			</ol>

			<h3>４．応募情報の利用および第三者提供</h3>
			<ol class="numList">
			<li>１）スタンプ帳は、Google AnalyticsおよびCookies等を用いてご利用情報を収集します。</li>
			<li>２）プレゼント企画またはスタンプラリーに関連して取得した各種情報については、ご利用状況の把握、スタンプラリー参加向上、広告販売およびプレゼント企画作りの目的でテレビ局またはその業務委託先において利用するほか、プレゼント企画またはスタンプラリーに関与する広告代理店のサービスの向上、販売促進等のために、当該広告代理店への提供を行うことがあります。</li>
			<li>３）ご利用情報を提供する際には、提供先には適切なデータ管理を行いうる事業者を選定し、提供先に対し、安全管理措置の整備、個人情報との紐づけの禁止、他の情報との突合の禁止、目的達成後の視聴データ等の速やかな消去等を契約上義務付けるなど、適切な措置をとります。</li>
			</ol>




				<div class="popupClose"><a href="javascript:void(0);" class="btn termsLogin termsLead" v-on:click="loginRedirect()">同意してログイン</a></div>
				<div class="popupClose"><a href="javascript:void(0);" class="btn termsLead" >同意しない</a></div>
		</div>
	</div>
</div>
</div>
</template>

<script>
import axios from 'axios' //追記
import cons from '../../util/const'

//フォーム用のデータ初期設定
export default {
	props: ['kyokucode', 'param'],
	localStorage: {
		loginToken: {
			type: String
		}
	},
	data() {
		return {
			cons: cons,
			user: {
				tel: '',
				valid:{
					tel: '',
				}
			},
			results : [],
			results_name : '',
			results_id : '',
			results_banner : '',
			results_css : '',
			results_tv_name : '',
			results_tv_logo : '',
			results_tv_uri : '',
			login_results : [],
			login_results_result_code : '',//結果コード
			login_results_error_message : '',//エラーメッセージ
			login_results_token : '',//トークン
			login_results_expired_at : '',//トークン有効期限
			login_results_user_answer_at : '',//アンケート回答日時
			login_results_user_expired_at : '',//アンケート回答期限日時
			message1 : '',
			message2 : ''
		}
	},
	created() {
	},
	mounted() {
		this.addPeople();
		axios.get("/api/stamprally_simple", {
			params: {
				code: this.param
			}
		})
		.then(response => {
			this.results = response.data;
			this.results_id = this.results.stamprally.id;
			this.results_name = this.results.stamprally.name;
			this.results_banner = this.results.stamprally.banner;
			this.results_css = this.results.stamprally.css;
			this.results_tv_name = this.results.stamprally.tv_station.name;
			this.results_tv_logo = this.results.stamprally.tv_station.logo;
			this.results_tv_uri = this.results.stamprally.tv_station.uri;
			this.$nextTick(() => {
				if(this.results_css){
					var link_style = $('<link>').attr({
						'rel': 'stylesheet',
						'href': this.cons.common.cssurl+this.results_css
					});
					$('body').append(link_style);
				}
			});
		})
		.catch(err => {
			(this.errored = true), (this.error = err);
			if(this.results.result_code == 'E010'){
				// 短縮URLの有効期限切れ
				this.$router.push("/general/error/expired/");
			}else if(this.results.result_code == 'E011'){
				// stアンプラリーが見つかりませんでした。
				this.$router.push("/general/error/noStamprally/");
			}else if(this.results.result_code == 'E012'){
				// スタンプラリーの表示期間外
				// ログイン画面表示のためスルー
				// this.$router.push("/stamp/error/outOfTerm/");
			}else{
				if (err.response.status == 500 || err.response.status == 502) {
					// Internal Server Error
					this.$router.push("/general/error/system/");
				}else if(err.response.status == 405){
					// Lambda API error
					this.$router.push("/general/error/system/");
				}
			}
		});

		this.$localStorage.set('shortUrlCode', this.param);
		this.$localStorage.set('kyokucode', this.kyokucode);
		this.login_results_token = this.$localStorage.get('loginToken', 'not entry');

		var $ = window.$;
		/* pop up */
		$(function(){
			$('.termsLead').on('click', function(){
				$('.overlay').stop(true).fadeOut(300);
				$('.popupWin').stop(true).fadeOut(300, function(){
					$('.popupWinWrap').removeClass('show');
					$('.leadTarget').prop('disabled', false).removeClass('disabled');
				});
			});

			$('.agreeCheck').on('change', function(){
				if($(this).prop('checked')){
					$('.loginBtn').removeClass('disabled');
				}else{
					$('.loginBtn').addClass('disabled');
				}
			});
		});
	},
	methods: {
		loginApi: function(dologin){
			var data = { code : this.param, phone : this.user.tel };
			axios.post("/api/login", data)
			.then(response => {
				this.login_results = response.data,
				this.login_results_result_code = this.login_results.result_code,//結果コード
				this.login_results_error_message = this.login_results.error_message;//エラーメッセージ

				if (dologin)
				{
					this.login_results_token = this.login_results.token,//トークン
					this.login_results_expired_at = this.login_results.expired_at,//トークン有効期限
					this.login_results_user_answer_at = this.login_results.user.answer_at,//アンケート回答日時
					this.login_results_user_expired_at = this.login_results.user.expired_at//アンケート回答期限日時
					this.$localStorage.set('loginToken', this.login_results_token)
				}

				// this.addLoginToken();
				if(this.login_results_result_code == 'E010'){ // 短縮URL期限切れ
					this.sessionStorage.removeItem('shortUrlCode');
					this.$router.push("/general/error/expired/");
				}else if(this.login_results_result_code == 'E011'){
					this.user.valid.tel = "セキュリティ保護のためロックされています。しばらく時間を置いてから再度ログインをお願いいたします。"
				}else if(this.login_results_result_code == 'E012'){
					this.user.valid.tel = "セキュリティ保護のためロックされています。しばらく時間を置いてから再度ログインをお願いいたします。"
				}else if(this.login_results_result_code == 'E013'){
					// エラー回数
					// ロックするまでの残り回数
					this.user.valid.tel = "エラー回数："+this.login_results.login_error_count+" 残り回数："+this.login_results.login_lock_count;
				}else{

					if (dologin)
					{
						var fromLogout = sessionStorage.getItem('fromLogout');
						if(fromLogout == 'fromLogout'){
							this.$router.push("/mypage/"+this.param+"/");
						}else{
							// アンケート回答済だったら詳細、未回答だったらアンケートへリダイレクト
							// var stampId = 1;
							if(this.results.result_code == 'E012'){
								this.$router.push("/stamp/error/outOfTerm/"+this.param);
							}else{
								if(this.login_results_user_answer_at){
									this.$router.push("/"+this.kyokucode+"/stamprally_detail/"+this.results_id+"/"+this.param+"/");
								}else{
									this.$router.push("/"+this.kyokucode+"/answer/"+this.results_id+"/"+this.param+"/");
								}
							}
						}
					} else {
						// $('.modal-pop').click();

						var target = "#terms";
						$('.overlay').stop(true).fadeIn(300);
						$(target).addClass('show');
						$(target).find('.popupWin').stop(true).fadeIn(300);

					}
				}
			})
			.catch(err => {
				(this.errored = true), (this.error = err);

				if(this.login_results_result_code == 'E010'){ // 短縮URL期限切れ
					this.sessionStorage.removeItem('shortUrlCode');
					// 短縮URLの有効期限切れ
					this.$router.push("/general/error/expired/");
				}else if(this.login_results_result_code == 'E011'){
					this.message1 = "";
					this.message2 = "";
					this.user.valid.tel = "セキュリティ保護のためロックされています。しばらく時間を置いてから再度ログインをお願いいたします。"
				}else if(this.login_results_result_code == 'E012'){
					this.message1 = "";
					this.message2 = "";
					this.user.valid.tel = "セキュリティ保護のためロックされています。しばらく時間を置いてから再度ログインをお願いいたします。"
				}else if(this.login_results_result_code == 'E013'){
					// エラー回数
					// ロックするまでの残り回数
					this.message1 = "携帯電話番号が違います。メッセージを受け取った携帯電話番号を入力してください。";
					this.message2 = "※3回失敗しますと、１時間ログインできなくなります";
					this.user.valid.tel = "エラー回数："+this.login_results.login_error_count+" 残り回数："+this.login_results.login_lock_count;
				}else{
					if (err.response.status == 500 || err.response.status == 502) {
						// Internal Server Error
						this.$router.push("/general/error/system/");
					}else if(err.response.status == 405){
						// Lambda API error
						this.$router.push("/general/error/system/");
					}
				}
			});
		},

		loginRedirect: function() {

			this.loginApi(true);

		},

		checkmethod: function(event){
			var error = 0;
			var tel_maxlength = 11;

			//valid tel
			var tel = this.user.tel
			if(!this.checkEmpty(tel)){
				this.user.valid.tel = "携帯電話番号を入力してください"
				error++;
			}else if(!this.checkNumeric(tel)){
				this.user.valid.tel = "携帯電話番号は半角数字のみで入力してください"
				error++;
			}else if(!this.checkMaxLength(tel, tel_maxlength)){
				this.user.valid.tel = "携帯電話番号は半角数字11桁で入力してください"
				error++;
			}
			else{
				this.user.valid.tel = "";
			}

			if(error  > 0){
				event.preventDefault()
			}
		},
		check2method: function(event){
			var error = 0;
			var tel_maxlength = 11;

			//valid tel
			var tel = this.user.tel
			if(!this.checkEmpty(tel)){
				this.user.valid.tel = "携帯電話番号を入力してください"
				error++;
			}else if(!this.checkNumeric(tel)){
				this.user.valid.tel = "携帯電話番号は半角数字のみで入力してください"
				error++;
			}else if(!this.checkMaxLength(tel, tel_maxlength)){
				this.user.valid.tel = "携帯電話番号は半角数字11桁で入力してください"
				error++;
			}
			else{
				this.user.valid.tel = "";
			}

			if(error  > 0){
				event.preventDefault()
			}else{
				this.loginApi(false);
			}
		},
		//未入力
		checkEmpty: function(inputdata){
			if(inputdata.trim() == ""){
				return false
			}else{
				return true
			}
		},
		//半角数字
		checkNumeric: function(inputdata){
			var regExp = /^[0-9_]*$/
			return regExp.test(inputdata);
		},
		//文字数制限
		checkMaxLength: function(inputdata, maxlength){
			var booleanLength = false
			if(inputdata.length != maxlength){
				booleanLength = false;
			}else{
				booleanLength = true;
			}
			return booleanLength
		},
		//メール形式
		checkMail: function(address){
			var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
			if (reg.test(address)) {
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>

<style scoped>
.error { color: red; }
</style>
