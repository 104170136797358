<template>
<div>
<div class="container">
	<div class="contents">
		<div class="section" v-if="$route.meta.isContact === true">
			<h1 class="pageTtl">お問い合わせ</h1>
			<p>お問い合わせの前に<a href="/faq.html" class="txtLink underline" target="_blank">よくあるお問い合わせ</a>をご利用ください。記載いただいた内容はお問い合わせ回答のみに利用し、それ以外の用途では使用しません。詳しくは利用規約をご確認ください。</p>
		</div>
		<div class="section" v-if="$route.meta.isConfirm === true">
			<h1 class="pageTtl">お問い合わせ内容の確認</h1>
			<p>以下の内容でお問合せします。内容を再度ご確認の上問題なければ、送信するを押してください。</p>
		</div>
		<div class="section" v-if="$route.meta.isComplete === true">
			<h1 class="pageTtl">お問い合わせを送信しました</h1>
			<p>お問い合わせありがとうございます。後日ご記入いただきましたメールアドレス宛に回答させていただきます。</p>
		</div>
		<div class="section formWrap">
			<router-view v-bind:user.sync="user"></router-view>
		</div>
	</div>
</div>
</div>
</template>

<script>

//フォーム用のデータ初期設定
export default {
	data() {
		return {
			user: {
				name: '',
				tel: '',
				mail: '',
				selectedTypes:'',
				message: '',
				valid:{
					name: '',
					tel: '',
					mail: '',
					selectedTypes:'',
					message: ''
				}
			}
		}
	}
}
</script>