export default Object.freeze({
    //共通系の設定
    common: {
        // 本番設定
        baseurl: "https://chpl.tv",
        cssurl: "https://chpl.tv/files/",

        //stg設定
        // baseurl: "https://stg.chpl.tv",
        // cssurl: "https://stg.chpl.tv/files/",

        // //ローカル設定
        // cssurl:"http://localhost:8001/css/",
        // baseurl:"http://localhost:8080",
        // cssurl:"http://localhost:8080/files/",
    },

    // 本番設定
    // 問い合わせ（全共通）
    contact: {
        sendurl: "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
        orgid: "00D0o0000017D7G",
        retURL: "/contact/complete",
        recordType: "0122j000000XdQvAAK",
        debug: 1,
        debugEmail: "hiraoka.c@leafnet.jp",
        usernameId: "00N0o00000NKgsv",
    },
    // 問い合わせ（スタンプラリー）
    stamprally_contact: {
        sendurl: "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
        orgid: "00D0o0000017D7G",
        retURL: "/contact/complete",
        recordType: "0122j000000XdQvAAK",
        debug: 1,
        debugEmail: "hiraoka.c@leafnet.jp",
        usernameId: "00N0o00000NKgsv",
    }

    //stg設定
    // 問い合わせ（全共通）
    // contact: {
    //     sendurl: "https://leafnet--testsand.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    //     orgid: "00DBU000000GxAc",
    //     retURL: "/contact/complete",
    //     recordType: "0122j000000XdQvAAK",
    //     debug: 1,
    //     debugEmail: "imazato@leafnet.jp",
    //     usernameId: "00N0o00000NKgsv",
    // },
    // // 問い合わせ（スタンプラリー）
    // stamprally_contact: {
    //     sendurl: "https://leafnet--testsand.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    //     orgid: "00DBU000000GxAc",
    //     retURL: "/contact/complete",
    //     recordType: "0122j000000XdQvAAK",
    //     debug: 1,
    //     debugEmail: "imazato@leafnet.jp",
    //     usernameId: "00N0o00000NKgsv",
    // }

});
