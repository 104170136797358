<template>
<div>
</div>
</template>

<script>
export default {
	props: {
		user: Object
	},
	mounted(){
		this.addPeople();
	}
};
</script>