import Vue from 'vue'
import axios from 'axios'
import jQuery from 'jquery'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router.js'
import VueLocalStorage from 'vue-localstorage' //追記
import resetcss from './assets/css/reset.css'
import commoncss from './assets/css/common.css'
import modulecss from './assets/css/module.css'

Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios
Vue.use(VueLocalStorage) //追記

const myMixin = {
  methods: {

    addPeople(){
      var __pParams = [];
      __pParams.push({client_id: '235',c_1: 'leafnet',c_2: 'ClientSite'});
      window.__pParams = __pParams;

      var head = document.getElementsByTagName('head')[0];

      var script = document.createElement('script');
      script.className = 'people';
      script.src = "https://cdn.d2-apps.net/js/tr.js";

      head.appendChild(script);
      // console.log("people tag add.");
      // console.log(location.href);


    },
  }
}
Vue.mixin(myMixin)   

global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')
Vue.config.productionTip = false

new Vue({
  router,
  resetcss,
  commoncss,
  modulecss,
  render: h => h(App),
}).$mount('#app')