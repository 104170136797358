<template>
	<div>
		<div id="box"></div>
	</div>
</template>
<script>
	import $ from 'jquery';
	import cons from '../../util/const'
	//フォーム用のデータ初期設定
	export default {
		data() {
			return {
				cons: cons,
			}
		},
		mounted() {
			$("#box").load("top_index.html");
			$('#header').hide();
			$('#footer').hide();
		},
	}
</script>