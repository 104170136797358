<template>
<div>

<div id="wrap">
<header id="header">
	<p class="logo"><img src="./assets/img/lg_chpl_tv.png" alt="チョップラ！"></p>
	<nav class="headNav" v-if="$route.meta.isHeadNav === true">
		<router-link :to="`/mypage/${ shortUrlCode }/`" class="mypage"><i class="icn"><img src="./assets/img/icn_mypage.png" alt="マイページ"></i></router-link>
		<a href="/logout" class="logout"><i class="icn"><img src="./assets/img/icn_logout.png" alt="ログアウト"></i></a>
	</nav>
<!-- / #header --></header>

<router-view></router-view>

<footer id="footer">
	<ul class="footNav">
		<li><a href="/service.html" target="_blank">ご利用方法</a></li>
		<li><a href="/privacy.html" target="_blank">プライバシーポリシー</a></li>
		<li><a href="http://leafnet.jp/index.html" target="_blank">運営会社</a></li>
		<li><a href="/contact/" target="_blank">問い合わせ</a></li>
	</ul>
	<p class="copyright">Copyright &copy; leafnet Co., Ltd.</p>
<!-- / #footer --></footer>
<!-- / #wrap --></div>
<div class="overlay"></div>
<div id="terms" class="popupWinWrap">
	<div class="popupWin">
		<a href="javascript:void(0);" class="close termsLead"></a>
		<h2 class="popupTtl">ご利用方法</h2>
		<div class="popupBody">
			<p>「チョップラ！」は、対象とするプレゼント企画に応募すると、スタンプがもらえて、集めたスタンプの数に応じて賞品の抽選に参加ができる、ふだんのプレゼント企画にちょっとだけ楽しみをプラスしてくれるサービスです。</p>
			<p>スタンプラリーのご利用について</p>
			<p>１．ご利用方法
１）番組内で告知した応募電話番号にスマートフォンから電話をかけるとスタンプを１つ獲得することができます。
２）電話をかけたスマートフォンの電話番号宛にSMS（ショートメッセージ）が送信されます。SMS本文に記載されたURLからスタンプ帳にアクセスし、スタンプラリーの詳細情報や獲得したスタンプを確認することができます。
３）SMSが送信されるタイミング、およびスタンプ帳にスタンプが押されるタイミングは、電話をかけてから約５分後になります。（応募が集中した際などに大幅な遅れが発生することがあります）
４）SMSは、電話をかけた回数に応じて送信されます。SMSの送信が不要な場合は、アンケート画面またはマイページからSMS受信設定を「受け取らない」に変更してください。
５）スタンプラリー対象のプレゼント企画の日程は、スタンプ帳で確認することができます。対象となる電話番号の受付期間内に複数回応募しても、獲得できるスタンプは１つとなります。
６）スタンプラリー対象のプレゼント企画が中止になった場合、プレゼントに応募することなくスタンプを獲得したものとみなします。
７）スタンプラリーの抽選はスタンプ帳に記載された抽選日に自動的に行われます。応募条件を達成し、かつアンケートに回答している方が抽選対象となります。
８）当選発表は当選者への連絡をもって行います。スタンプ帳上で当選結果の発表は行いません。
９）プレゼントの発送は、当選のご連絡からおよそ2か月以内に行います。
10）スタンプ帳は、スタンプラリー終了後、原則として30日間閲覧することができます。

２．ご注意事項
１）ログイン後２４時間操作がなかった場合、自動的にログアウトされます。ログアウトされた場合、再度ログイン画面へ戻ってログインをし直してください。
２）ログインページで携帯電話番号の入力を３回間違えた場合、最後の操作から１時間ロックされます。ログイン操作は、１時間以上経過後に行ってください。
３）スタンプは、電話をかけたスマートフォンの電話番号に対し付与されるため、異なる電話番号から参加した場合や、スタンプラリー開催中に電話番号を変更された場合、獲得したスタンプを引き継ぐことはできません。
４）スタンプラリーについてのお問い合わせは、「お問い合わせ」「企画に関するお問い合わせ」ボタンから行うことができます。

３．ご利用条件
１）スタンプラリーに参加するための通話料、スタンプ帳を閲覧するための接続費用は、利用者のご負担になります。
２）スタンプ帳にアクセスするため、SMSを受信できる携帯電話が必要になります。
３）スタンプ帳の推奨環境は以下のとおりとします。
iOS
・iOS13以降 / iPhone6以上 / Safari最新版
Android
・Android9.0以降 / Google Chrome最新版

４．応募情報の利用および第三者提供
１）スタンプ帳は、Google AnalyticsおよびCookies等を用いてご利用情報を収集します。
２）プレゼント企画またはスタンプラリーに関連して取得した各種情報については、ご利用状況の把握、スタンプラリー参加向上、広告販売およびプレゼント企画作りの目的でテレビ局またはその業務委託先において利用するほか、プレゼント企画またはスタンプラリーに関与する広告代理店のサービスの向上、販売促進等のために、当該広告代理店への提供を行うことがあります。
</p>
			<div class="popupClose"><a href="javascript:void(0);" class="btn termsLead">閉じる</a></div>
		</div>
	</div>
</div>

</div>
</template>

<script>
	//フォーム用のデータ初期設定
	export default {
		props: ['id'],
		data() {
			return {
				shortUrlCode :'',
			}
		},
		watch: {
			'$route' (routeInstance) {
				this.createTitleDesc(routeInstance);
			}
		},
		mounted() {
			var routeInstance = this.$route;
			this.createTitleDesc(routeInstance);

			this.shortUrlCode = localStorage.getItem('shortUrlCode')
		},
		methods : {
			createTitleDesc : function(routeInstance){
				// タイトルを設定
				if(routeInstance.meta.title){
						var setTitle = '';
					if(routeInstance.meta.title == "TOP"){
						setTitle = 'チョップラ！ テレビとあなたの生活にチョットしたプラスを';
					} else {
						setTitle = routeInstance.meta.title + ' | チョップラ！';
					}
					document.title = setTitle;
				} else {
					document.title = 'チョップラ！'
				}
			}
		}
	}
</script>