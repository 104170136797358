<template>
<div>
	<form v-on:submit="$router.push('/smssetting/completed/:id/')" id="contact">
		<div class="form">
			<label for="sms" class="formLabel">SMS受信設定</label><br>
			<ul class="radioList">
				<li>
					<div class="radioWrap"><input v-model="sms_notification" type="radio" name="sms" value="true" id="sms01"><label for="sms01">受け取る</label></div>
				</li>
				<li>
					<div class="radioWrap"><input v-model="sms_notification" type="radio" name="sms" value="false" id="sms02"><label for="sms02">受け取らない</label></div>
				</li>
			</ul>
		</div>
		<div class="layoutC mt30"><input v-on:click="sendSmsSetting" type="submit" name="submit" value="設定を保存する" class="btn"></div>
	</form>
	<div class="layoutC mt30"><router-link :to="`/mypage/${ id }/`" class="btn">マイページへ戻る</router-link></div>

</div>
</template>

<script>
import axios from 'axios' //追記
//import $ from 'jquery';
export default {
	props: {
		smssettings: Object,
		id: String,
	},
	data() {
		return {
			sms_notification: '',
			results: [],
		}
	},
	mounted(){
		
		this.addPeople();

		var apiLoginToken = localStorage.getItem('loginToken');

		if(apiLoginToken == null || apiLoginToken == 'undefined'){
			var kyokucode = localStorage.getItem('kyokucode')
			if(kyokucode == '' || kyokucode == null){
				kyokucode = 'stp'+this.channel;
			}
			var shortUrlCode = localStorage.getItem('shortUrlCode')
			if(shortUrlCode == '' || shortUrlCode == null){
				shortUrlCode = this.id;
			}

			sessionStorage.setItem('fromLogout', 'fromLogout');

			this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");

		}

		const token = "Bearer " + apiLoginToken;

		axios.get("/api/user_info", {
			params: {},
			headers: {
				'Content-Type':'application/json',
				"Authorization": token
			},
			data: {}
		})
		.then(response => {
			this.results = response.data;

			this.sms_notification = this.results.user.is_sms_notification;

		})
		.catch(err => {
			(this.errored = true), (this.error = err);
			if (err.response.status == 500 || err.response.status == 502) {
				// Internal Server Error
				this.$router.push("/general/error/system/");
			}else if(err.response.status == 403 || err.response.status == 400){
				var kyokucode = localStorage.getItem('kyokucode')
				if(kyokucode == '' || kyokucode == null){
					kyokucode = 'stp999';
				}
				var shortUrlCode = localStorage.getItem('shortUrlCode')
				if(shortUrlCode == '' || shortUrlCode == null){
					shortUrlCode = this.id;
				}

				sessionStorage.setItem('fromLogout', 'fromLogout');
				this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
			}else if(err.response.status == 404 || err.response.status == 405){
				// Lambda API error
				this.$router.push("/general/error/system/");
			}
		});
	},
	methods: {
		sendSmsSetting:function(event){

			this.apiLoginToken = this.$localStorage.get('loginToken', 'not entry')
			const token = "Bearer "+this.apiLoginToken;
			var notification = '';

			if(this.sms_notification == "true"){
				notification = Boolean(true);
			}else if(this.sms_notification === true){
				notification = Boolean(true);
			}else if(this.sms_notification == "false"){
				notification = Boolean(false);
			}else if(this.sms_notification === false){
				notification = Boolean(false);
			}else{
				notification = Boolean(true);
			}

			axios.post(
				"/api/settings",
				{is_sms_notification : notification},
				{
					headers: {
						'Content-Type':'application/json',
						"Authorization": token
					}
				}
			).then(response => {
				this.results = response.data;
				this.$router.push("/smssetting/complete/"+this.id);
			})
			.catch(err => {
				(this.message = 'SMS設定の変更に失敗しました'),
				(this.error = err);
				if (err.response.status == 500 || err.response.status == 502) {
					// Internal Server Error
					this.$router.push("/general/error/system/");
				}else if(err.response.status == 403 || err.response.status == 400){
					var kyokucode = localStorage.getItem('kyokucode')
					if(kyokucode == '' || kyokucode == null){
						kyokucode = 'stp999';
					}
					var shortUrlCode = localStorage.getItem('shortUrlCode')
					if(shortUrlCode == '' || shortUrlCode == null){
						shortUrlCode = this.id;
					}

					sessionStorage.setItem('fromLogout', 'fromLogout');
					this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
				}else if(err.response.status == 404 || err.response.status == 405){
					// Lambda API error
					this.$router.push("/general/error/system/");
				}
			});

			event.preventDefault()
		}
	}
};
</script>
