<template>
<div>
	<h1 class="mypageTtl">参加中のスタンプラリー</h1>

	<div class="stampRallyList">
		<p v-if="noStamprally == 1" class="endedTxt">参加中のスタンプラリーはありません</p>
		<li v-else v-for="stamprally in stamprallies" v-bind:key="stamprally.id">
			<router-link :to="`/${ stamprally.station_id }/stamprally_detail/${ stamprally.id }/${ stamprally.param }/`" >
				<div class="img"><img v-bind:src="stamprally.banner" alt=""></div>
				<div class="txt">
					<div class="ttlWrap">
						<p class="preline ttl">{{stamprally.description}}</p>
					</div>
					<p class="period">{{stamprally.open}} 〜 {{stamprally.close}}</p>
				</div>
			</router-link>
		</li>
	</div>
	<div class="btnWrap mt40"><router-link :to="`/smssetting/${ id }/`" class="btn btnS"><span>SMS 送信設定</span></router-link></div>

</div>
</template>

<script>
	import axios from 'axios' //追記
	// import $ from 'jquery';
	// import cons from '../../util/const'

	//フォーム用のデータ初期設定
	export default {

	props: ['id'],
	data() {
		return {
			// cons: cons,
			stamprallies: [],
			noStamprally :'',
			stationCode :'',
			shortUrlCode :'',
			apiLoginToken :'',
		}
	},
	beforeMount() {
		if (window.name != "any") {
			location.reload();
			window.name = "any";
		} else {
			window.name = "";
		}
	},
	mounted() {
		this.addPeople();
		// this.shortUrlCode = this.$localStorage.get('shortUrlCode', 'not entry');
		// if(this.shortUrlCode == 'not entry'){
		// 	this.$router.push("/general/error/session/");
		// }
		var apiLoginToken = localStorage.getItem('loginToken');
		this.stationCode = localStorage.getItem('kyokucode')
		this.shortUrlCode = localStorage.getItem('shortUrlCode')

		if(apiLoginToken == null || apiLoginToken == 'undefined'){
			var kyokucode = localStorage.getItem('kyokucode')
			if(kyokucode == '' || kyokucode == null){
				kyokucode = 'stp'+this.channel;
			}
			if(this.shortUrlCode == '' || this.shortUrlCode == null || this.shortUrlCode == undefined){
				this.shortUrlCode = this.id;
			}

			sessionStorage.setItem('fromLogout', 'fromLogout');

			this.$router.push("/"+kyokucode+"/"+this.shortUrlCode+"/");
		}

		const token = "Bearer "+apiLoginToken;
		axios.get("/api/mypage", {
			headers: {
				'Content-Type':'application/json',
				"Authorization": token
			},
			data: {} //←これ！！！
		})

		.then(response => {

			this.stamprallies = response.data.stamprallies;

			sessionStorage.removeItem('fromLogout');

			if(this.stamprallies.length == 0){
				this.noStamprally = 1;
			}
			// this.$nextTick(() => {
			// 	if (window.name != "any") {
			// 		location.reload();
			// 		window.name = "any";
			// 	} else {
			// 		window.name = "";
			// 	}
			// });
		})
		.catch(err => {

			(this.errored = true),(this.error = err);

			if (err.response.status == 500 || err.response.status == 502) {
				// Internal Server Error
				this.$router.push("/general/error/system/");
			}else if(err.response.status == 403 || err.response.status == 400){
				var kyokucode = localStorage.getItem('kyokucode')
				if(kyokucode == '' || kyokucode == null){
					kyokucode = 'stp999';
				}
				var shortUrlCode = localStorage.getItem('shortUrlCode')
				if(shortUrlCode == '' || shortUrlCode == null){
					shortUrlCode = this.id;
				}

				sessionStorage.setItem('fromLogout', 'fromLogout');
				this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
			}else if(err.response.status == 405){
				// Lambda API error
				this.$router.push("/general/error/system/");
			}
		});

	},
	methods: {
	}

}
</script>