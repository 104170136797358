<template>
<div>
<div id="mainVisual"><div class="inner"><img v-bind:src="stamprally.banner" v-bind:alt="stamprally.description"></div></div>
<div class="container">
	<div class="contents">
		<div class="broadcastingLogo"><img v-bind:src="tv_station.logo" alt=""></div>
		<div class="section" v-if="$route.meta.isContact === true">
			<h1 class="pageTtl">お問い合わせ</h1>
			<p>お問い合わせの前に<a href="/faq.html" class="txtLink underline" target="_blank">よくあるお問い合わせ</a>をご利用ください。記載いただいた内容はお問い合わせ回答のみに利用し、それ以外の用途では使用しません。詳しくは利用規約をご確認ください。</p>
		</div>
		<div class="section" v-if="$route.meta.isConfirm === true">
			<h1 class="pageTtl">お問い合わせ内容の確認</h1>
			<p>以下の内容でお問合せします。内容をご確認の上、【送信する】を押してください。</p>
		</div>
		<div class="section" v-if="$route.meta.isComplete === true">
			<h1 class="pageTtl">お問い合わせを送信しました</h1>
			<p>お問い合わせありがとうございます。後日ご記入いただきましたメールアドレス宛に回答させていただきます。</p>
		</div>
		<div class="section formWrap">
			<router-view v-bind:user.sync="user"></router-view>
		</div>
	</div>
</div>
</div>
</template>

<script>
import axios from 'axios'
import cons from '../../util/const'

//フォーム用のデータ初期設定
export default {
	props: ['kyokucode', 'id', 'channel'],
	data() {
		return {
			cons: cons,
			stamprally: [],
			tv_station: [],
			awards: [],
			stamp_book: [],
			user: {
				stamp_rally:{
					kyokucode:this.kyokucode,
					id:this.id,
					channel:this.channel,
					stamprally_no:'',
				},
				name: '',
				tel: '',
				mail: '',
				selectedTypes:'',
				message: '',
				valid:{
					name: '',
					tel: '',
					mail: '',
					selectedTypes:'',
					message: ''
				}
			}
		}
	},
	mounted(){
		this.addPeople();
		var $ = window.$;
		var apiLoginToken = localStorage.getItem('loginToken');
		if(apiLoginToken == null || apiLoginToken == 'undefined'){
				var kyokucode = localStorage.getItem('kyokucode')
				if(kyokucode == '' || kyokucode == null){
					kyokucode = 'stp'+this.channel;
				}
				var shortUrlCode = localStorage.getItem('shortUrlCode')
				if(shortUrlCode == '' || shortUrlCode == null){
					shortUrlCode = this.id;
				}

			this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");
		}
		var token = "Bearer " + apiLoginToken;

		axios.get("/api/stamprally_simple", {
			params: {
				code: this.id,	//短縮URLのパラメータ
			},
			headers: {
				'Content-Type':'application/json',
				"Authorization": token
			},
			data: {}
		})
		.then(response => {
			this.results = response.data;
			this.stamprally = this.results.stamprally
			this.tv_station = this.results.stamprally.tv_station
			//独自CSS適用
			this.$nextTick(() => {
				if(this.stamprally.css){
					var link_style = $('<link>').attr({
						'rel': 'stylesheet',
						'href': this.cons.common.cssurl+this.stamprally.css
					});
					$('body').append(link_style);
				}
			});

			this.user.stamp_rally.stamprally_no = this.results.stamprally.stamprally_no;

			if(this.results.result_code == 'E010'){
				// 短縮URLの有効期限切れ
				this.$router.push("/general/error/expired/");
			}else if(this.results.result_code == 'E011'){
				// stアンプラリーが見つかりませんでした。
				this.$router.push("/general/error/noStamprally/");
			}else if(this.results.result_code == 'E012'){
				// スタンプラリーの表示期間外
				this.$router.push("/stamp/error/outOfTerm/"+this.id);
			}

		})
		.catch(err => {
			(this.errored = true), (this.error = err);
			if(this.results.result_code == 'E010'){
				// 短縮URLの有効期限切れ
				this.$router.push("/general/error/expired/");
			}else if(this.results.result_code == 'E011'){
				// stアンプラリーが見つかりませんでした。
				this.$router.push("/general/error/noStamprally/");
			}else if(this.results.result_code == 'E012'){
				// スタンプラリーの表示期間外
				this.$router.push("/stamp/error/outOfTerm/"+this.id);
			}else{
				if (err.response.status == 500 || err.response.status == 502) {
					// Internal Server Error
					this.$router.push("/general/error/system/");
				}else if(err.response.status == 404 || err.response.status == 405){
					// Lambda API error
					this.$router.push("/general/error/system/");
				}
			}
		});

	}
}
</script>