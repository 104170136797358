import Vue from 'vue'
import VueRouter from "vue-router"
import VueAnalytics from 'vue-analytics'

//ティザー
import TopIndex from "./components/top/Index.vue"
//ログイン
import LoginIndex from "./components/login/Index.vue"
// import LoginForm from "./components/login/Form.vue"

//アンケート
import AnswerIndex from "./components/answer/Index.vue"
//スタンプラリー詳細
import StamprallyDetail from "./components/stamprally/Detail.vue"
//マイページ
import MypageIndex from "./components/mypage/Index.vue"
import MypageList from "./components/mypage/List.vue"
//SMS設定
import SmsIndex from "./components/smssetting/Index.vue"
import SmsForm from "./components/smssetting/Form.vue"
import SmsComplete from "./components/smssetting/Complete.vue"

//問い合わせ（スタンプラリー用）
import StamprallyContactIndex from "./components/stamprally_contact/Index.vue"
import StamprallyContactForm from "./components/stamprally_contact/Form.vue"
import StamprallyContactConfirm from "./components/stamprally_contact/Confirm.vue"
import StamprallyContactComplete from "./components/stamprally_contact/Complete.vue"
//問い合わせ
import ContactIndex from "./components/contact/Index.vue"
import ContactForm from "./components/contact/Form.vue"
import ContactConfirm from "./components/contact/Confirm.vue"
import ContactComplete from "./components/contact/Complete.vue"
//ログアウト
import LogoutIndex from "./components/logout/Index.vue"
//汎用エラー
import StampErrorIndex from "./components/stamp_error/Index.vue"
import GeneralErrorIndex from "./components/general_error/Index.vue"
//404エラー
import NotFound from "./components/not_found/Index.vue"

Vue.use(VueRouter)

const routes = [


	//アンケート
	{
        path: "/:kyokucode/answer/:channel/:id/",
        component: AnswerIndex,
        props: true,
        meta:{
            isHeadNav:false,
            title: 'アンケート'
        }
	},
	//マイページ
    {
        path: "/mypage/:id/",
        component: MypageIndex,
        children: [
          {
            path: "",
            component: MypageList,
            props: true,
            meta: {
                isComplete: false,
                isHeadNav:true,
                title: 'マイページ'
              }
          },
        ]
    },
    //SMS設定
    {
        path: "/smssetting/complete/:id/",
        component: SmsIndex,
        props: true,
        children: [
              {
                path: "/smssetting/complete/:id/",
                component: SmsComplete,
                props: true,
                meta: {
                  isComplete: true,
                  isHeadNav:true,
                  title: 'SMS送信設定'
                }
              }
            ]
    },
    {
        path: "/smssetting/:id/",
        component: SmsIndex,
        props: true,
        children: [
            {
                path: "/smssetting/:id/",
                component: SmsForm,
                props: true,
                meta: {
                    isComplete: false,
                    isHeadNav:true,
                    title: 'SMS送信設定'
                  }
              },
            ]
    },
	//お問い合わせ（スタンプラリー用）
    {
        path: "/:kyokucode/stamprally_detail/:channel/contact/:id",
        component: StamprallyContactIndex,
        props: true,
        children: [
          {
            path: "/:kyokucode/stamprally_detail/:channel/contact/:id",
            component: StamprallyContactForm,
            props: true,
            meta: {
                isContact: true,
                isConfirm: false,
                isComplete: false,
                isHeadNav:true,
                title: 'スタンプラリーに関するお問い合わせ'
              }
          },
          {
            path: "/:kyokucode/stamprally_detail/:channel/contact/confirm/:id/",
            component: StamprallyContactConfirm,
            props: true,
            meta: {
                isContact: false,
				isConfirm: true,
				isComplete: false,
				isHeadNav:true,
                title: 'スタンプラリーに関するお問い合わせ'
            }
          },
          {
            path: "/:kyokucode/stamprally_detail/:channel/contact/complete/:id/",
            component: StamprallyContactComplete,
            props: true,
            meta: {
                isContact: false,
                isConfirm: false,
                isComplete: true,
                isHeadNav:true,
                title: 'スタンプラリーに関するお問い合わせ'
            }
          }
        ]
	},
    //スタンプラリー詳細
    {
        path: "/:kyokucode/stamprally_detail/:channel/:id/",
        component: StamprallyDetail,
        props: true,
        meta:{
            isHeadNav:true,
            title: 'スタンプラリー'
        }
    },
	//問い合わせ
    {
        path: "/contact",
        component: ContactIndex,
        children: [
          {
            path: "/contact",
            component: ContactForm,
            meta: {
                isContact: true,
                isConfirm: false,
                isComplete: false,
                isHeadNav:false,
                title: 'お問い合わせ'
              }
          },
          {
            path: "confirm",
            component: ContactConfirm,
            meta: {
                isContact: false,
                isConfirm: true,
                isComplete: false,
                isHeadNav:false,
                title: 'お問い合わせ'
            }
          },
          {
            path: "complete",
            component: ContactComplete,
            meta: {
                isContact: false,
                isConfirm: false,
                isComplete: true,
                isHeadNav:false,
                title: 'お問い合わせ'
            }
          }
        ]
	},
	//ログアウト
    {
        path: "/logout",
        component: LogoutIndex,
        props: true,
        meta:{
            isHeadNav:false,
            title: 'ログアウト'
        }
    },
    //ティザー
    {
        path: '/teaser/index.html', redirect: '/'
    },
	//ログイン
	{
        path: "/:kyokucode/:param/",
        component: LoginIndex,
        props: true,
        meta:{
            isHeadNav:false,
            title: ''
        }
        // children: [
        //   {
        //     path: "/login/:code/:param/contact",
        //     component: LoginForm,
        //     props: true,
        //     meta: {
        //         isComplete: false
        //       }
        //   },
        // ]

	},
	//汎用エラー
    {
        path: "/stamp/error/:code/:id",
        component: StampErrorIndex,
        props: true,
        meta:{
            isHeadNav:true,
            title: 'スタンプラリー終了'
        }
    },
    {
        path: "/general/error/:code/",
        component: GeneralErrorIndex,
        props: true,
        meta:{
            isHeadNav:false,
            title: 'エラー'
        }
    },
    {
        path: "/",
        component: TopIndex,
        props: true,
        meta:{
            isHeadNav:false,
            title: 'TOP'
        }
    },
	//404エラー
    {
        path: '*',
        component: NotFound,
        props: true,
        meta:{
            isHeadNav:false,
            title: 'Not Found 404'
        }
    },

 ]

const router = new VueRouter({
    mode: "history",
    // routes: routes,
    // routes: [],
    // scrollBehavior () {
    //     return { x: 0, y: 0 }
    // }

    scrollBehavior: async (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }

        const findEl = async (hash, x) => {
            return document.querySelector(hash) ||
                new Promise((resolve, reject) => {
                    if (x > 50) {
                        return resolve();
                    }
                    setTimeout(() => {
                        resolve(findEl(hash, ++x || 1));
                    }, 250);
                });
        }

        if (to.hash) {
            const el = await findEl(to.hash);

            if ('scrollBehavior' in document.documentElement.style) {
                return window.scrollTo({top: el.offsetTop, behavior: 'smooth'});
            } else {
                return window.scrollTo(0, el.offsetTop);
            }
        }

        return {x: 0, y: 0};
    },
    routes: routes,
    // routes: []
})


export default router


Vue.use(VueAnalytics, {
    id: 'UA-178382088-1',
    router
  })
