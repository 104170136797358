<template>
<div>
	<div class="layoutC">
		<router-link :to="`/mypage/${ id }/`" class="btn"><span>マイページに戻る</span></router-link>
	</div>
</div>
</template>

<script>
export default {
	props: {
		smssettings: Object,
		id: String,
	},
	mounted(){

		this.addPeople();

		var apiLoginToken = localStorage.getItem('loginToken');

		if(apiLoginToken == null || apiLoginToken == 'undefined'){
			var kyokucode = localStorage.getItem('kyokucode')
			if(kyokucode == '' || kyokucode == null){
				kyokucode = 'stp'+this.channel;
			}
			var shortUrlCode = localStorage.getItem('shortUrlCode')
			if(shortUrlCode == '' || shortUrlCode == null){
				shortUrlCode = this.id;
			}

			sessionStorage.setItem('fromLogout', 'fromLogout');

			this.$router.push("/"+kyokucode+"/"+shortUrlCode+"/");

		}

	}
};
</script>