<template>
<div>
	<div class="section">
		<div class="btnWrap">
			<router-link :to="`/${ kyokucode }/stamprally_detail/${ channel }/${ id }/`" class="btn">スタンプラリーへ戻る</router-link>
		</div>
	</div>
</div>
</template>

<script>
import cons from '../../util/const'

export default {
	data(){
		return {
			cons:cons
		};
	},
	props: {
		kyokucode:String,
		user: Object,
		id:String,
		channel:String,
	},
	mounted(){
		this.addPeople();
	}
};
</script>